 #cookie-bar {
     background: rgba(50, 50, 58, 0.9);
     position: fixed;
     z-index: 99;
     left: 0px;
     bottom: 0px;
     width: 100%;
     padding: 8px 20px;
     font-size: 11px;
     color: #c3c3c3;

     a {
         color: $red;
         font-weight: 600;
     }

     #close-cookie {
         margin-top: 3px;
         width: 14px;
         cursor: pointer;
     }
 }