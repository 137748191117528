#news-page,
#slug-page {
    background: rgb(6, 4, 4);
    background: linear-gradient(180deg, rgba(6, 4, 4, 1) 14%, #3b0606 84%);

    .bars-wrapper {
        .sidebar {
            position: absolute;
            top: 1vw;
            width: 5vw;
            max-width: 74px;
            height: auto;

            &:first-of-type {
                left: 0;
                @include transform(scaleX(-1));
            }

            &:last-of-type {
                right: 0;
            }
        }
    }

    hr {
        margin: 3rem 0;
        border: 1px solid $red;
    }

    .card {
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &:hover .card-image {
            transform: translate3d(0px, 0px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        }

        .card-image {
            border: 1px solid $red;
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;
            transition: transform .25s cubic-bezier(.25, .46, .45, .94), filter .45s cubic-bezier(.25, .46, .45, .94), box-shadow .45s cubic-bezier(.25, .46, .45, .94);

        }

        .card-body {
            padding: 0.4rem 0;

            a {
                color: $white;
                text-decoration: none;
            }

            h3 {
                font-size: 1.7rem;
                margin-bottom: 0.1rem;
            }

            h4 {
                font-size: 1.2rem;
            }
        }
    }

}

#news-page {
    h1 {
        margin: 7rem 0 1rem;
        text-align: center;
        text-transform: uppercase;
        color: $red;
        font-style: italic;
    }

    #content-page {
        min-height: 100vh;

        .center-bar {
            margin: auto;
            padding: 0 2rem;
        }

        .card {
            &.card-featured {
                max-width: 1000px;

                @media(max-width:$mobile) {
                    margin-bottom: 1.5rem
                }
            }

            &.card-last {
                @media(min-width: 768px) and (max-width:$tablet) {
                    margin-top: 2.4vw !important;
                }

            }

            .date {
                color: rgba(250, 250, 250, 0.6);
                letter-spacing: 1px;
                text-transform: uppercase;
                padding: 6px 0;
                font-size: 11px;
                line-height: 110%;
            }

            .overlay {
                position: absolute;
                bottom: 0.5rem;
                left: 0;
                padding: 0 1rem;
            }
        }
    }
}

#slug-page {
    .header-image {
        width: 100%;
        max-height: 80vh;
        object-fit: cover;
        overflow: hidden;
        border-bottom: 2px solid $red;
        margin-bottom: 2rem;
    }

    #content-post {
        color: $white;
        min-height: 100vh;

        .date {
            color: rgba(250, 250, 250, 0.6);
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 6px 0;
            font-size: 12px;
            line-height: 110%;
        }

        .center-bar {
            margin: auto;
            padding: 0;
        }


        .content-block {
            padding: 1rem 0 3rem;

            img {
                max-width: 100%;
                height: auto;
                margin: 1rem 0 1rem;
            }

            iframe {
                max-width: 100%;
                margin: 1rem 0 1rem;
            }

            a {
                color: yellow;
                text-decoration: none;
                transition: all 0.4s ease;

                &:hover {
                    color: black;
                }
            }
        }
    }
}