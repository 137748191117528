#notfound-page {
    h1 {
        margin: 7rem 0 1rem;
        text-align: center;
        text-transform: uppercase;
        color: $red;
        font-style: italic;
    }

    .bars-wrapper {
        .sidebar {
            position: absolute;
            top: 1vw;
            width: 5vw;
            max-width: 74px;
            height: auto;

            &:first-of-type {
                left: 0;
                @include transform(scaleX(-1));
            }

            &:last-of-type {
                right: 0;
            }
        }
    }

    #content-page {
        min-height: 80vh;

        .center-bar {
            margin: auto;
            padding: 0 2rem;
        }

        .card {
            &.card-featured {
                @media(max-width:$mobile) {
                    margin-bottom: 1.5rem
                }
            }

            &.card-featuerd {
                @media(max-width:$mobile) {
                    margin-bottom: 1.5rem
                }
            }

            .date {
                color: rgba(250, 250, 250, 0.6);
                letter-spacing: 1px;
                text-transform: uppercase;
                padding: 6px 0;
                font-size: 11px;
                line-height: 110%;
            }

            .overlay {
                position: absolute;
                bottom: 0.5rem;
                left: 0;
                padding: 0 1rem;
            }
        }
    }
}