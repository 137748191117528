#faq-page {
    background: rgb(6, 4, 4);
    background: linear-gradient(180deg, rgba(6, 4, 4, 1) 14%, #3b0606 84%);

    .bars-wrapper {
        .sidebar {
            position: absolute;
            top: 1vw;
            width: 5vw;
            max-width: 74px;
            height: auto;

            &:first-of-type {
                left: 0;
                @include transform(scaleX(-1));
            }

            &:last-of-type {
                right: 0;
            }
        }
    }

    h1 {
        margin: 7rem 0 1rem;
        text-align: center;
        text-transform: uppercase;
        color: $red;
        font-style: italic;
    }

    --bs-accordion-active-color: transparent;

    #faq {
        min-height: 70vh;
        max-width: 990px;
        margin: auto;

        .accordion-item {
            border: 0;

            .accordion-button {
                border-radius: 0;
                border: 0;
                background-color: rgb(86, 0, 0);
                color: #fff;
                border-bottom: 1px solid rgb(97, 31, 31);
                box-shadow: none !important;
                border-left: 4px solid #fff;

                &.collapsed {
                    border-color: #6d2727;
                }

                &:focus {
                    box-shadow: none;
                    outline: none;
                }

                &::after {
                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23FFF%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
                }
            }

            .accordion-body {
                color: #d7d7d7;
                padding: 2rem 1.4rem;

                a {
                    color: yellow;
                    text-decoration: none;
                    transition: all 0.4s ease;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }


}