    header {
        position: absolute;
        top: -200px;
        left: 0;
        z-index: 1;
        width: 100%;
        opacity: 0;
        transition: all 0.8s 1s ease-in-out;
        padding: 10px;
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7) 100%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.7) 100%);

        @media(max-width: $mobile) {
            padding: 0px;

        }

        &.active {
            top: 0;
            opacity: 1;
        }

        .brand-logo {
            display: block;
            max-width: 240px;

            @media(max-width: $mobile) {
                max-width: 160px;
            }

            @media(max-width: $phone) {
                max-width: 120px;
            }
        }

        nav {
            @media(max-width: $tablet) {
                margin-left: 0.2rem;
            }

            .link {
                position: relative;
                color: $white;
                font-size: 1.1rem;
                margin: 0 0.8rem;
                text-decoration: none;
                text-transform: uppercase;
                transition: all 0.4s ease;

                @media(max-width: $tablet) {
                    font-size: 1rem;
                }

                @media(max-width: $mobile) {
                    font-size: 0.8rem;
                    margin: 0 0.6rem;
                }

                @media(max-width: $phone) {
                    margin: 0 0.4rem;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 0;
                    height: 2px;
                    background: $red;
                    transition: all 0.4s ease;
                }

                &:hover,
                &.active {
                    color: $red;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        .social-logo {
            position: relative;
            top: 15px;
            left: -66px;

            @media(max-width: $mobile) {
                top: 20px;
                left: -55px;
                @include transform(scale(0.9));
            }

            a {
                display: block;
                height: 36px;
                width: 36px;
                text-align: center;
                position: absolute;
                transition: all 0.3s ease;

                svg {
                    max-width: 100%;
                    height: auto;

                    path {
                        transition: all 0.6s ease;
                    }
                }

                img {
                    position: absolute;
                    top: 47%;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    margin: auto;
                    width: 18px;
                    height: auto;
                    @include transform(translateY(-50%));
                }

                &:hover {
                    svg path {
                        fill: $dark-red;
                    }
                }
            }
        }
    }