#agegate {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.90);

    .wrapper {
        position: relative;
        width: 100%;
        max-width: 460px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 1.8rem 1rem 1rem;
        margin: auto;
        border: 1px solid $red;

        .title {
            font-size: 1.6rem;
        }

        .form-control {
            background-color: rgba(255, 255, 255, 0.05);
            border-color: rgba(255, 255, 255, 0.4);
            border-radius: 0;
            color: rgb(255, 255, 255);
            font-size: 17px;
            min-width: 0;
            padding: 16px;
            outline: none;
            line-height: 20px;
            transition: .3s ease all;
            margin: 0 10px;

            &:focus {
                background-color: rgba(255, 255, 255, 0.098);
                border-color: rgba(255, 255, 255, 0.2);
                box-shadow: none;
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .form-group {
            margin-bottom: 20px;
        }

        .alert-danger {
            margin-top: 20px;
            border-radius: 0;
            font-size: 16px;
        }

        .btn-shape {
            padding: 10px 12px;
            outline: none;
            background-color: #500000;
            cursor: pointer;
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            border: none;
            height: 43.91px;
            border-radius: 0;
            transition: .3s ease background-color;
        }

        .note {
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            text-align: center;
        }
    }
}