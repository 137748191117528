footer {
    position: relative;
    background: $black;
    padding: 3rem 0 1rem;

    .brand-logo {
        display: block;
        max-width: 170px;

        @media (max-width: $mobile) {
            text-align: center;
            margin: auto;
        }
    }

    nav {
        margin-bottom: 1rem;

        @media (max-width: $mobile) {
            text-align: center;
            margin: 0 auto 1rem;
        }

        .link {
            color: $white;
            font-size: 12px;
            margin: 0 0.5rem;
            text-decoration: none;
            transition: color 0.4s ease;

            &:hover {
                color: $red;
            }
        }
    }

    .legal-links {
        .legal-logos {
            .item {
                width: 130px;
            }

            .border {
                border: 1px solid $red;
                box-shadow: 0 0 5px 4px $dark-red2;
            }
        }
    }

    .legal {
        font-size: 10px;
        color: $gray;
        text-align: left;
        margin-top: 5px;

        a {
            color: $red;
            transition: all 0.4s ease;

            &:hover {
                color: $white;
            }
        }
    }
}