@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;0,500;1,700&display=swap');
@import "vars";
@import '~bootstrap';
@import '~bootstrap-icons';
@import '~swiper/swiper-bundle.css';
@import "~glightbox/dist/css/glightbox.css";
@import "animate";
@import "mixins";
@import "keyframes";
@import "main";
@import "header";
@import "footer";
@import "agegate";
@import "cookie";
@import "home";
@import "news";
@import "faq";
@import "notfound";