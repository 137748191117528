body {
    margin: 0;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn-shape {
    padding: 10px 12px;
    outline: none;
    background-color: #500000;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: none;
    height: 43.91px;
    border-radius: 0;
    transition: .3s ease background-color;
}

.btn-cta {
    outline: none;
    background-color: rgb(211, 0, 0);
    cursor: pointer;
    margin: 1rem auto 2.4rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border: none;
    height: 40px;
    border-radius: 0;
    transition: .3s ease background-color;
    font-size: 1.4rem;
    font-family: Kanit;
    font-style: italic;
    line-height: 1.3;
    padding: 5px 25px;

    &:hover {
        background-color: #500000;
    }
}

.btn-theme.sml {
    padding: 4px 8px;
    color: $white;
    font-size: 11px;
    border-radius: 0;
    border: 0;
    background-color: $black;
    transition: all 0.4s ease;

    &:hover {
        background-color: $red;
    }
}

.glightbox-clean .gslide-description {
    background-color: transparent;
    padding: 0 15px;

    .btn {
        font-size: 0.85rem;
        padding: 0.3rem 1rem;
        width: auto;
        height: auto;
        margin: 0 4px;
        color: #fff;

        &:hover {
            background-color: #230000;
        }
    }
}

.inview {
    opacity: 0;

    &.animated {
        opacity: 0;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;

        &.delay-1 {
            -webkit-animation-dely: 0.4s;
            animation-delay: 0.4s;
        }

        &.delay-2 {
            -webkit-animation-dely: 0.6s;
            animation-delay: 0.6s;
        }

        &.delay-3 {
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }

        &.delay-4 {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }
    }

    &.fadeInUp {
        animation-name: fadeInUp;
    }
}

.bkg-zoom {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    z-index: 0;
}

.modal.show {
    display: block;
}

.modal-content {
    border: 0;
    background-color: transparent !important;
    border-radius: 0;
    padding: 1rem;

    .modal-header {
        border-radius: 0;
        border: 0;
        padding: 1rem 0;
    }

    .modal-body {
        padding: 0;
        background-color: $black;

        .ratio {
            iframe {
                border: 1px solid $dark-red;
            }
        }
    }
}