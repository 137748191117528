$use-all: true;
/**** VARIABLES ****/
$darken-overlay: (
  10: 0.1,
  15: 0.15,
  20: 0.2,
  25: 0.25,
  30: 0.3,
  35: 0.35,
  40: 0.4,
  45: 0.45,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  80: 0.8
);
$delays: (
  1: 0.3,
  2: 0.6,
  3: 0.9,
  4: 1.2,
  5: 1.5,
  6: 1.6,
  7: 1.9,
  8: 2.1
);
$maxW: (
  500,
  600,
  700,
  800,
  900,
  1000,
  1200,
  1400
);
/***** BREAKPOINTS OVERRIDES *****/
$fullsize: 1799px;
$desktop: 1399px;
$laptop: 1199px;
$tablet: 991px;
$mobile: 767px;
$phone: 530px;
$iphone: 375px;
$iphone5: 374px;
/***** COLORS *****/
$body-bg: #000;
$black: #000;
$dark: #171717;
$white: #fff;
$dark-red: #500000;
$dark-red2: #690000;
$red: #c51e1e;
$orange: #fa6424;
$gray: #9b9b9b;
$colors: (
  "white": $white,
  "red": $red,
  "dark-red": $dark-red,
  "dark-red2": $dark-red2,
  "black": $black,
  "dark": $dark,
  "orange": $orange,
  "gray": $gray
);
/***** BOOTSTRAP OVERRIDES *****/
$bootstrap-sass-asset-helper: true !important;
$border-radius: 10px;
$btn-close-width: 1.5em;
$spacer: 1rem;
$card-group-margin: 1rem;
$font-family-base: 'Kanit', cursive;
$headings-font-family: 'Kanit', cursive;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 400;
$font-weight-base: 400;
$line-height-base: 1.4;
$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 3.8;
$h3-font-size: $font-size-base * 2.6;
$h4-font-size: $font-size-base * 2;
$h5-font-size: $font-size-base * 1.25;
$headings-margin-bottom: calc($spacer / 2);
$headings-font-weight: $font-weight-bold;
$headings-line-height: 0.9;
$headings-color: null;
$display1-size: $font-size-base * 4.7;
$display2-size: $font-size-base * 3.5;
$display3-size: $font-size-base * 3;
$display4-size: $font-size-base * 2;
$display1-weight: $font-weight-light;
$display2-weight: $font-weight-light;
$display3-weight: $font-weight-light;
$display4-weight: $font-weight-light;
$display-line-height: 1;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  sl: 1800px) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  sl: 1780px) !default;
$card-border-radius: 0;
$card-border-color: transparent;
/***** CUSTOM *****/
$headerHeight: 500px;
$minHeaderHeight: 480px;
$assets-path: "../assets";