#home-page {
    position: relative;
    text-align: center;
    font-size: calc(10px + 1vmin);
    color: white;

    .heading {
        color: $red;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 700;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    }

    .svg-btn {
        display: inline-block;
        max-width: 148px;
        height: 43.91px;
        margin: 4px;
        background-color: transparent;
        border: 0;

        svg path {
            transition: all 0.4s ease;
        }

        &:hover {
            svg path {
                fill: #500000;
            }
        }

        &.bottom {
            @media(max-width:$phone) {
                max-width: 170px;
            }
        }

        &.svg-mobile {

            .mobile {
                display: none;
            }

            @media(max-width:$phone) {
                .desktop {
                    display: none;
                }

                .mobile {
                    display: inline-block;
                }
            }
        }
    }

    .overlay {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    #intro {
        position: relative;
        z-index: 0;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;

        @media(max-width: $mobile) {
            height: 90vh;
        }

        .heading {
            color: $white;
        }

        .layer {

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 70%;
                margin: auto;
                z-index: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: opacity 0.3s ease;
                background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 100%);
                background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 100%);
                background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 100%);
            }

            img {
                position: relative;
            }
        }

        &.is-scrolled {
            .layer:before {
                opacity: 1;
            }
        }

        .video-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            pointer-events: none;
            overflow: hidden;

            iframe {
                width: 100vw;
                height: 56.25vw;
                min-height: 100vh;
                min-width: 177.77vh;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
            }
        }

        .center-bar {
            width: 100%;
            margin: auto;
        }

        h3 {
            font-style: inherit;
        }

        .sidebar {
            position: absolute;
            bottom: 2vw;
            width: 6vw;
            max-width: 90px;
            height: auto;

            &:first-of-type {
                left: 0;
                @include transform(scaleX(-1));
            }

            &:last-of-type {
                right: 0;
            }
        }
    }

    #about {
        position: relative;
        overflow: hidden;
        height: 50vh;
        max-height: 560px;

        .sidebar {
            position: absolute;
            top: 2vw;
            width: 3vw;
            height: auto;

            &#about-left-bar {
                left: 0;
                @include transform(scaleX(-1));
            }

            &#about-right-bar {
                right: 0;
            }


        }

        .center-bar {
            width: 70%;
            padding-top: 5%;
            margin: auto;
        }

        .bars-wrapper {
            position: relative;

            .bars {
                position: absolute;
                top: 4vw;
                left: 0;
                right: 0;
                width: 15vw;
                height: auto;
                margin: auto;
                opacity: 0;
                @include transform(scale(-0.6));

                &#bar-1 {
                    @include transform(scale(-0.6) translateX(-40%));
                }

                &#bar-3 {
                    @include transform(scale(-0.6) translateX(40%));
                }
            }
        }

        .overlay {
            margin: auto;
            max-width: 870px;
        }
    }

    #news {
        position: relative;
        border-top: 2px solid $dark-red2;
        background: rgb(48, 0, 0);
        background: -moz-linear-gradient(180deg, rgba(48, 0, 0, 1) 0%, rgba(48, 0, 0, 1) 23%, rgba(13, 0, 0, 1) 83%);
        background: -webkit-linear-gradient(180deg, rgba(48, 0, 0, 1) 0%, rgba(48, 0, 0, 1) 23%, rgba(13, 0, 0, 1) 83%);
        background: linear-gradient(180deg, rgba(48, 0, 0, 1) 0%, rgba(48, 0, 0, 1) 23%, rgba(13, 0, 0, 1) 83%);
        padding-bottom: 3rem;

        h2 {
            position: relative;
            top: -35px;
            padding: 0.5rem 5rem;
            background: rgb(48, 0, 0);
            border-top: 2px solid $dark-red2;
            display: inline-block;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: -2px;
                height: 35px;
                width: 3rem;
                background-color: #300000;
                border-top: 2px solid $dark-red2;
            }

            &:before {
                left: -1rem;
                border-left: 2px solid $dark-red2;
                -moz-transform: skew(-45deg);
                -webkit-transform: skew(-45deg);
                transform: skew(-45deg);
            }

            &:after {
                right: -1rem;
                border-right: 2px solid $dark-red2;
                -moz-transform: skew(45deg);
                -webkit-transform: skew(45deg);
                transform: skew(45deg);
            }
        }

        .swiper-block {
            position: relative;
            top: -1.8rem;
            padding: 0 9vw;

            .card {
                background-color: transparent;
                display: block;
                width: 100%;
                height: auto;
                border: 0px solid $black;

                .card-image {
                    position: relative;
                    overflow: hidden;
                    backface-visibility: hidden;
                    transition: all 1s ease-in-out;
                    display: inline-block;
                    border: 2px solid $dark-red;
                    transition: all 2s ease-in-out;

                    img {
                        opacity: 0.8;
                        transition: all 2s ease-in-out;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        transition: all 0.7s ease-in-out;
                        box-shadow: inset 0 0 20px 7px rgba(0, 0, 0, 0.65);
                    }
                }

                .card-body {
                    padding: 0.2rem 0.2rem;
                    color: $white;
                    font-size: 1rem;
                    text-align: left;

                    .date {
                        color: rgba(250, 250, 250, 0.6);
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        padding: 6px 0;
                        font-size: 11px;
                        line-height: 110%;
                    }

                    a {
                        text-decoration: none;

                        h4 {
                            font-size: 1.2rem;
                            line-height: 1;
                            color: $white;

                            @media(max-width: $mobile) {
                                font-size: 1rem;
                            }
                        }
                    }
                }

                &:hover {
                    &:after {
                        box-shadow: inset 0 0 8px 3px rgba(0, 0, 0, 0.45);
                    }

                    .card-image {
                        border: 2px solid $red;
                        box-shadow: 0 0 8px rgba(215, 0, 0, 0.6);

                        img {
                            opacity: 1;
                            will-change: transform;
                            @include transform(scale3d(1.1, 1.1, 1));
                        }
                    }

                }
            }

            .swiper-navigation {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));
                }

                .swiper-prev-news-arrow {
                    left: 0.2rem;

                    &:hover {
                        @include transform(translateX(-0.2rem));
                    }
                }

                .swiper-next-news-arrow {
                    right: 0.2rem;
                    @include transform(scaleX(-1));

                    &:hover {
                        @include transform(scaleX(-1) translateX(-0.2rem));
                    }
                }

                .swiper-arrows {
                    opacity: 0.8;
                    position: absolute;
                    top: 0;
                    width: 5vw;
                    height: 100%;
                    cursor: pointer;
                    transition: all 0.4s ease;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    #media {
        position: relative;
        border-top: 2px solid $dark-red2;
        background: rgb(48, 0, 0);
        background: -moz-linear-gradient(180deg, rgba(48, 0, 0, 1) 0%, rgba(48, 0, 0, 1) 23%, rgba(13, 0, 0, 1) 83%);
        background: -webkit-linear-gradient(180deg, rgba(48, 0, 0, 1) 0%, rgba(48, 0, 0, 1) 23%, rgba(13, 0, 0, 1) 83%);
        background: linear-gradient(180deg, rgba(48, 0, 0, 1) 0%, rgba(48, 0, 0, 1) 23%, rgba(13, 0, 0, 1) 83%);

        h2 {
            position: relative;
            top: -35px;
            padding: 0.5rem 5rem;
            background: rgb(48, 0, 0);
            border-top: 2px solid $dark-red2;
            display: inline-block;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: -2px;
                height: 35px;
                width: 3rem;
                background-color: #300000;
                border-top: 2px solid $dark-red2;
            }

            &:before {
                left: -1rem;
                border-left: 2px solid $dark-red2;
                -moz-transform: skew(-45deg);
                -webkit-transform: skew(-45deg);
                transform: skew(-45deg);
            }

            &:after {
                right: -1rem;
                border-right: 2px solid $dark-red2;
                -moz-transform: skew(45deg);
                -webkit-transform: skew(45deg);
                transform: skew(45deg);
            }
        }

        .swiper-block {
            position: relative;
            top: -1.8rem;
            padding: 0 9vw;

            .glightbox {
                display: block;
                width: 100%;
                height: auto;
                position: relative;
                overflow: hidden;
                background-color: $black;
                border: 2px solid $dark-red;
                backface-visibility: hidden;
                transition: all 1s ease-in-out;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    transition: all 0.7s ease-in-out;
                    box-shadow: inset 0 0 20px 7px rgba(0, 0, 0, 0.65);
                }

                img {
                    display: inline-block;
                    opacity: 0.8;
                    transition: all 2s ease-in-out;
                }

                &:hover {
                    border: 2px solid $red;
                    box-shadow: 0 0 8px rgba(215, 0, 0, 0.6);

                    &:after {
                        box-shadow: inset 0 0 8px 3px rgba(0, 0, 0, 0.45);
                    }

                    img {
                        opacity: 1;
                        will-change: transform;
                        @include transform(scale3d(1.1, 1.1, 1));
                    }

                }
            }

            .swiper-navigation {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));
                }

                .swiper-prev-arrow {
                    left: 0.2rem;

                    &:hover {
                        @include transform(translateX(-0.2rem));
                    }
                }

                .swiper-next-arrow {
                    right: 0.2rem;
                    @include transform(scaleX(-1));

                    &:hover {
                        @include transform(scaleX(-1) translateX(-0.2rem));
                    }
                }

                .swiper-arrows {
                    opacity: 0.8;
                    position: absolute;
                    top: 0;
                    width: 5vw;
                    height: 100%;
                    cursor: pointer;
                    transition: all 0.4s ease;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    #wishlist {
        position: relative;
        overflow: hidden;
        padding-bottom: 3rem;

        #wishlist-zoom {
            &:after {
                content: '';
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
            }

        }

        h2.heading {
            padding-top: 0.6rem;
        }

        .bars-wrapper {
            position: relative;

            .sidebar {
                position: absolute;
                top: 0;
                width: 9vw;
                height: auto;

                &:last-child {
                    right: 0;
                    @include transform(scaleX(-1));
                }

                &:first-child {
                    left: 0;
                }
            }
        }

        .wishlist-selection {
            max-width: 960px;
            margin: auto;
            position: relative;
            padding-bottom: 10rem;

            .soon {
                color: #fff;
                text-transform: uppercase;
                margin-top: 1rem;
                position: relative;

                @media(max-width: $tablet) {
                    font-size: 1.4rem;
                }

                @media(max-width: $mobile) {
                    font-size: 1rem;
                }

                @media(max-width: $phone) {
                    margin-top: 0.6rem;
                    font-size: 0.9rem;
                }
            }

            .wrapper {
                padding-top: 5%;
                position: relative;
                z-index: 1;

                @media(max-width: $tablet) {
                    padding-top: 3%;
                }

                @media(max-width: $mobile) {
                    padding-top: 2%; //4%
                }

                @media(max-width: $phone) {
                    padding-top: 1%; //3%
                }

                span {
                    position: relative;
                    display: inline-block;
                    max-width: 86px;
                    width: 8vw;
                    margin: 0 1.7rem;

                    @media(max-width: 1024px) {
                        width: 10vw;
                    }

                    @media(max-width: $tablet) {
                        width: 8vw;
                    }

                    @media(max-width: $mobile) {
                        margin: 0 5%;
                    }

                    &.ps svg {
                        margin: -0.78rem;

                        @media(max-width: $tablet) {
                            margin: -0.70rem;
                        }

                        @media(max-width: $mobile) {
                            margin: -0.4rem;
                        }

                        @media(max-width: $phone) {
                            margin: -0.3rem;
                        }
                    }

                    &.epic svg {
                        padding: 0.35rem;

                        @media(max-width: $mobile) {
                            padding: 0.27rem;
                        }

                        @media(max-width: $phone) {
                            padding: 0.1rem;
                        }
                    }

                    svg path {
                        transition: all 0.4s ease;
                    }

                    path.active {
                        fill: $gray;
                    }

                    a {
                        path.active {
                            fill: $white;
                        }

                        &:hover {
                            svg {
                                path.active {
                                    fill: $red;
                                }
                            }
                        }
                    }
                }
            }

            .wish-block {
                position: absolute;
                z-index: 0;
            }
        }

        .layer {
            margin-top: 4rem;

            h3 {
                margin-top: 2rem;
            }
        }

        .divider {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    #signup {
        position: relative;
        overflow: hidden;
        border-top: 4px solid rgb(170, 0, 0);

        .bar-line {
            position: absolute;
            top: -4px;
            left: 0;
            z-index: 1;
            margin: auto;
            right: auto;
            width: 100%;

            img {
                width: 16%;

                @media(max-width: $mobile) {
                    width: 25%;
                }
            }
        }

        .signup-wrapper {
            text-transform: uppercase;
            margin: auto;
            padding: 5rem 20px 9rem;

            @media(max-width: 1024px) {
                max-width: 600px;
            }

            @media(max-width: $tablet) {
                padding-bottom: 15rem;
            }

            @media(max-width: $mobile) {
                padding-bottom: 20rem;
            }

            h4 {
                font-weight: 300;
                font-size: 1.1rem;
                line-height: 1;

                span {
                    color: $white;
                }
            }

            form {
                margin: 2rem auto 0;
                max-width: 500px;

                @media(max-width: $mobile) {
                    display: block !important;
                }

                .col-auto {
                    padding: 0;

                    &.shape {
                        position: relative;
                        max-width: 330px;

                        input {
                            position: absolute;
                            top: 5px;
                            left: 0;
                            border-radius: 0;
                            background-color: transparent;
                            border: 0 solid $dark-red2;
                            padding: 0.5rem 2rem;
                            text-transform: uppercase;
                            color: #fff;

                            &:focus,
                            &:active {
                                box-shadow: none !important;
                            }

                            @media(max-width: $mobile) {
                                position: relative;
                                top: 0;
                                border: 2px solid $dark-red2;
                                background-color: rgba(0, 0, 0, 0.8);
                            }
                        }


                        @media(max-width: $mobile) {
                            max-width: inherit;

                            svg {
                                display: none;
                            }
                        }
                    }
                }

                .svg-btn {
                    margin: 2px 0 0 0;
                    max-width: 162px;

                    @media(max-width: $mobile) {
                        margin: 5px 0 0 0;
                    }
                }
            }

            .svg-btn {
                margin: 0;

                @media(max-width: $mobile) {
                    margin: 3px 0 0 0;
                }

                @media(max-width:$mobile) {
                    .desktop {
                        display: none;
                    }

                    .mobile {
                        display: inline-block;
                    }
                }

            }

            .alert {
                clip-path: polygon(100% 0, 100% 50%, 95% 100%, 5% 100%, 0 50%, 0 0);
                margin: 1rem auto;
                max-width: 30rem;
                padding: 0.3rem 0.6rem;
                border-radius: 0;
                line-height: 1;
            }
        }
    }
}